.App {
  font-family: 'Arial', sans-serif;
  text-align: center;
  background-color: #181818;
  max-width: 100%;
  margin: 100px auto 0;
}

.Title {
  font-size: 60px;
  font-weight: bold;
  padding: 10px;
  color: #39bb35;
  align-items: center;
}

/* Nav Bar */

.NavBar {
  background-color: #181818;
  color: #39bb35;
  display: flex;
  justify-content: center;
  padding: 5px;
  width: 100%;
}

.NavLinks {
  display: flex;
  justify-content: center;
  align-items: center;
}

.NavLinks a {
  color: #39bb35;
  text-decoration: none;
  padding: 10px 15px;
  justify-content: center;
  font-size: 18px;
  transition: background-color 0.3s ease;
}

.NavLinks a:hover {
  background-color: #39bb35;
  color: #181818;
}

/* Loading Dots */

.Loading-dots {
  display: flex;
  justify-content: center;
  padding: 20px;
  align-items: center;
}

.Dot {
  width: 12px;
  height: 12px;
  background-color: #39bb35;
  border-radius: 50%;
  margin: 0 5px;
  animation: blink 1.5s infinite;
}

.Dot:nth-child(2) {
  animation-delay: 0.5s; /* Delay the animation for the second dot */
}

.Dot:nth-child(3) {
  animation-delay: 1s; /* Delay the animation for the third dot */
}

@keyframes blink {
  50% {
      opacity: 0;
  }
}